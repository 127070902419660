import React, { useState } from 'react';
import '../style/Nav.css';
import { GiHamburgerMenu } from "react-icons/gi";

import { NavLink } from 'react-router-dom';


function Nav() {

  const [menuOpen, setMenuOpen] = useState(false);

  function desplegarMenu() {
    setMenuOpen(!menuOpen);
  }
  
  return (
    <div className={`topnav ${menuOpen ? 'responsive' : ''}`} id='myTopnav'>
      <NavLink 
        to='/' 
        className={({ isActive }) => (isActive ? 'active' : '')}>
          Home
      </NavLink>
      <NavLink 
        to='/personalizados' 
        className={({ isActive }) => (isActive ? 'active' : '')}>
          Personalizados
      </NavLink>
      <NavLink 
        to='/librosbs'
        /* Utiliza el objeto isActive para saber si es V o F */ 
        className={({ isActive }) => (isActive ? 'active' : '')}>
          Libros BS
      </NavLink>
      <NavLink 
        to='/infantiles'        
        className={({ isActive }) => (isActive ? 'active' : '')}>
          Infantiles
      </NavLink>
      <NavLink 
        to='/adolescentes' 
        className={({ isActive }) => (isActive ? 'active' : '')}>
          Adolescentes
      </NavLink>
      <NavLink 
        to='/adultos' 
        className={({ isActive }) => (isActive ? 'active' : '')}>
          Adultos
      </NavLink>
      <NavLink 
        to='/juegos' 
        className={({ isActive }) => (isActive ? 'active' : '')}>
          Juegos
      </NavLink>
      <NavLink 
        to='/tecnologia' 
        className={({ isActive }) => (isActive ? 'active' : '')}>
          Tecnología
      </NavLink>
      <a 
        href='#' 
        className='icon' 
        onClick={desplegarMenu}>
          <GiHamburgerMenu />
      </a>
    </div>
  );
}

export default Nav;