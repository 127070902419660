import '../style/Juegos.css';
import Producto from '../componentes/Producto';

function Juegos() {
  return (
    <main className='main-juegos'>
      <div className='contenedor-titulo-libros'>
        <h1 className='titulos-libros'>Destacados Juegos de Mesa</h1>
      </div>
      <div className='contenedor-juegos'>
        <Producto
          ruta={'https://amzn.to/4aoisJS'}
          imagen={'https://m.media-amazon.com/images/I/812noL4zLgL._AC_SX679_.jpg'}
          altDescripcion={'Imagen Juego de Stonemaier Games'}
          titulo={'Stonemaier Games: Wingspan,Expansión Asia, juego de mesa de estrategia, 14 años en adelante.'}
        />
        <Producto
          ruta={'https://amzn.to/3Va3eE3'}
          imagen={'https://m.media-amazon.com/images/I/91tN15xlQcL._AC_SY879_.jpg'}
          altDescripcion={'Imagen Pegasus Spiele'}
          titulo={'Pegasus Spiele- Juegos de Mesa'}
        />
        <Producto
          ruta={'https://amzn.to/3ytXsnK'}
          imagen={'https://m.media-amazon.com/images/I/71K8gdgfFmL._AC_SX679_.jpg'}
          altDescripcion={'Imagen Greater Than Games Sentinels of The Multiverse'}
          titulo={'Greater Than Games Sentinels of The Multiverse: Rook City Renegades | Expansión de juego de mesa de estrategia cooperativa'}
        />
        <Producto
          ruta={'https://amzn.to/4aoisJS'}
          imagen={'https://m.media-amazon.com/images/I/71xse8oLKQL._AC_SX679_.jpg'}
          altDescripcion={'Imagen Juego de mesa Coca Cola-Opoly'}
          titulo={'Juego de mesa Coca Cola-Opoly (mpc)'}
          changeClass='producto-img-h'  
        />
        <Producto
          ruta={'https://amzn.to/3QPqmVx'}
          imagen={'https://m.media-amazon.com/images/I/81aAVNUE2JL._AC_SX679_.jpg'}
          altDescripcion={'Imagen Juego Devir- Carcassone: Expansión La Abadía y El Alcade'}
          titulo={'Devir- Carcassone: Expansión La Abadía y El Alcade, Juego de Mesa, Juego de Mesa 8 años'}
        />
        <Producto
          ruta={'https://amzn.to/3wKxzzs'}
          imagen={'https://m.media-amazon.com/images/I/61lh3L1yR2L._AC_SX679_.jpg'}
          altDescripcion={'Imagen juego Space Cowboys'}
          titulo={'Space Cowboys | Splendor Marvel | Juego de Mesa de Estrategia para Adultos y Familias | A Partir de 10 Años'}
        />
        <Producto
          ruta={'https://amzn.to/44PfpZW'}
          imagen={'https://m.media-amazon.com/images/I/71KGCi3Zf7L._AC_SX679_.jpg'}
          altDescripcion={'Imagen Juego de mesa RA'}
          titulo={'Juego de mesa RA para 2 a 5 jugadores'}
        />
        <Producto
          ruta={'https://amzn.to/3WSHk9G'}
          imagen={'https://m.media-amazon.com/images/I/81TFRNdR6wL._AC_SX679_.jpg'}
          altDescripcion={'Imagen juego Acquire'}
          titulo={'Renegade Game Studios Adquiere un juego de mesa de estrategia para 2 a 6 jugadores, a partir de 12 años con 2 modos de juego'}
        />
        <div className='contenedor-titulo-libros'>
          <h1 className='titulos-libros'>Juegos y juguetes para niños</h1>
        </div>
        <Producto
          ruta={'https://amzn.to/44NAbJH'}
          imagen={'https://m.media-amazon.com/images/I/615068v7nnL._AC_SX679_.jpg'}
          altDescripcion={'Imagen Juguetes de playa para niños'}
          titulo={'Juguetes de playa para niños, juguetes de arena para niños - Juego de juguetes de playa divertido de 19 piezas'}
        />
        <Producto
          ruta={'https://amzn.to/3wMfqBs'}
          imagen={'https://m.media-amazon.com/images/I/81C9UWPn2iL._AC_SX679_.jpg'}
          altDescripcion={'Imagen juego Kit de Exploración'}
          titulo={'Frasheng Kit de Exploración para Niños 28 en 1, Juego de Explorador para Niños...'}
        />
        <Producto
          ruta={'https://amzn.to/3KhSz3D'}
          imagen={'https://m.media-amazon.com/images/I/61i7U28f61L._AC_SX679_.jpg'}
          altDescripcion={'Imagen juego ISAKEN Marionetas'}
          titulo={'ISAKEN Marionetas de Mano para niños...'}
          changeClass='producto-img-h' 
        />
        <Producto
          ruta={'https://amzn.to/4dSrfa6'}
          imagen={'https://m.media-amazon.com/images/I/51kayNl6UaL._AC_SX679_.jpg'}
          altDescripcion={'Imagen juego Peluche dinosaurio'}
          titulo={'CUTeFiorino Peluche dinosaurio dinosaurio tornillo de montaje de inteligencia DIY...'}          
        />
        <Producto
          ruta={'https://amzn.to/4bs9EUI'}
          imagen={'https://m.media-amazon.com/images/I/71qnqGDPUwL._AC_SX679_.jpg'}
          altDescripcion={'Imagen juego Glop Alien'}
          titulo={'Glop Alien, Juegos de Mesa Niños 8+ Años y Adultos, Divertidos Juegos de Mesa...'}          
        />
        <Producto
          ruta={'https://amzn.to/3QUmXVv'}
          imagen={'https://m.media-amazon.com/images/I/71OoHuV7ciL._AC_SX679_.jpg'}
          altDescripcion={'Imagen juego Glop 3Pizza'}
          titulo={'Glop 3Pizza, Juegos de Mesa para Niños a Partir de 8 años y Adultos, Juego de Cartas...'}          
        />
        <Producto
          ruta={'https://amzn.to/3URmBjV'}
          imagen={'https://m.media-amazon.com/images/I/718gYlGHa0L._AC_SX679_.jpg'}
          altDescripcion={'Imagen juego Halatua Mesa Dardos'}
          titulo={'Halatua Mesa Dardos para niños...'}          
        />
        <Producto
          ruta={'https://amzn.to/4bKVLR3'}
          imagen={'https://m.media-amazon.com/images/I/71T-bHqV7HL._AC_SY879_.jpg'}
          altDescripcion={'Imagen juego Simba Verter Agua'}
          titulo={'Simba, Verter Agua y apretando los Anillos al Objetivo, Juego de Viaje...'}          
        />
      </div>
    </main>

  );
};

export default Juegos;