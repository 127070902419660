import '../style/Adolescentes.css';
import Libro from '../componentes/Libro';

function Adolescentes() {
  return (
    <main className='main-adolescentes'>
      <div className='contenedor-titulo-libros'>
        <h1 className='titulos-libros'>Destacados</h1>
      </div>
      <div className='contenedor-libros-adolescentes'> 
        <Libro
          ruta={'https://amzn.to/3KaZbkn'}
          imagen={'https://m.media-amazon.com/images/I/71uW+xeVb-L._SY522_.jpg'}
          altDescripcion={'Foto Libro Cómo Dejar de Pensar Demasiado'}
          titulo={'Cómo Dejar de Pensar Demasiado'}
        />  
        <Libro
          ruta={'https://amzn.to/3xWipHE'}
          imagen={'https://m.media-amazon.com/images/I/818dQkV5mrL._SY522_.jpg'}
          altDescripcion={'Foto Libro Fabricante de lágrimas'}
          titulo={'Fabricante de lágrimas'}
        />   
        <Libro
          ruta={'https://amzn.to/4bbkkXd'}
          imagen={'https://m.media-amazon.com/images/I/91OI4F8Fa7L._SY522_.jpg'}
          altDescripcion={'Foto Libro Alas de sangre'}
          titulo={'Alas de sangre (Empíreo 1)'}
        />   
        <Libro
          ruta={'https://amzn.to/3UPGTdC'}
          imagen={'https://m.media-amazon.com/images/I/51Sc0wg6a1L._SY522_.jpg'}
          altDescripcion={'Foto Libro Hasta que el Sol deje de brillar'}
          titulo={'Hasta que el Sol deje de brillar'}
        />   
        <Libro
          ruta={'https://amzn.to/3yrCqpH'}
          imagen={'https://m.media-amazon.com/images/I/81xgVCqs62L._SY522_.jpg'}
          altDescripcion={'Foto Libro El guardián de espadas'}
          titulo={'El guardián de espadas'}
        />   
        <Libro
          ruta={'https://amzn.to/3UPcg81'}
          imagen={'https://m.media-amazon.com/images/I/810fPW7n42L._SY522_.jpg'}
          altDescripcion={'Foto Libro Promesa de Campbell'}
          titulo={'Promesa de Campbell'}
        />   
        <Libro
          ruta={'https://amzn.to/4bdrbQ2'}
          imagen={'https://m.media-amazon.com/images/I/812NgH11-JL._SY522_.jpg'}
          altDescripcion={'Foto Libro Powerless (edición especial limitada)'}
          titulo={'Powerless (edición especial limitada)'}
        />   
        <Libro
          ruta={'https://amzn.to/44QpQwk'}
          imagen={'https://m.media-amazon.com/images/I/91d4p1AWbXL._SY522_.jpg'}
          altDescripcion={'Foto Libro Aunque llueva en primavera'}
          titulo={'Aunque llueva en primavera'}
        />   
        <Libro
          ruta={'https://amzn.to/3UlBzOV'}
          imagen={'https://m.media-amazon.com/images/I/61AnrD587gL._SY522_.jpg'}
          altDescripcion={'Foto Libro Tuerto, maldito y enamorado'}
          titulo={'Tuerto, maldito y enamorado'}
        />   
        <Libro
          ruta={'https://amzn.to/3JJIfBb'}
          imagen={'https://m.media-amazon.com/images/I/81Z5JXZqSlL._SY522_.jpg'}
          altDescripcion={'Foto Libro Binding 13'}
          titulo={'Binding 13 (Los chicos de Tommen 1)'}
        />   
            
      </div>

    </main>
  );
};

export default Adolescentes;