// authContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


// Crea el contexto de autenticación
const AuthContext = createContext();

// Usuario y contraseña hardcodeados
const HARD_CODED_USER = {
  username: 'admin',
  password: 'admin123',
  role: 'admin'
};

// Proveedor del contexto de autenticación
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null); // Estado del usuario, inicialmente null
  const navigate = useNavigate();

// Cargar el usuario desde localStorage al iniciar
useEffect(() => {
  const storedUser = localStorage.getItem('user');
  if (storedUser) {
    setUser(JSON.parse(storedUser));
  }
}, []);


  // Función para iniciar sesión
  const login = (username, password) => {
    const cleanUsername = String(username).trim();
    const cleanPassword = String(password).trim();

    if (cleanUsername === HARD_CODED_USER.username && cleanPassword === HARD_CODED_USER.password) {
      const loggedInUser = { username: HARD_CODED_USER.username, role: HARD_CODED_USER.role };
      setUser(loggedInUser);      
      localStorage.setItem('user', JSON.stringify(loggedInUser));
      // Redirigir a la página de admin
      navigate('/elbuenadmin');
    } else {      
      alert('Usuario o contraseña incorrectos');
    }
  };

  // Función para cerrar sesión
  const logout = () => {
    setUser(null);
    localStorage.removeItem('user'); // Eliminar el usuario del almacenamiento local
    navigate('/login'); // Redirigir al login después de cerrar sesión
  };

  // Verifica si el usuario tiene el rol de administrador
  const isAdmin = () => {
    return user && user.role === 'admin';
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, isAdmin }}>
      {children}
    </AuthContext.Provider>
  );
};

// Hook para usar el contexto de autenticación
export const useAuth = () => useContext(AuthContext);