import React, { useState } from 'react';
import EnviarMail from '../js/JavaMail';

import '../style/FormLibroPers.css';

function FormLibroPers({ sectionIdentifier }) {

  const [formData, setFormData] = useState({
    nombre: '',
    tapa: '',
    email: '',
    sectionIdentifier: sectionIdentifier    
  });

  const handleSubmit = (event) => {
    event.preventDefault(); // Evitar que el formulario se envíe de forma predeterminada
    
    // Aquí puedes realizar cualquier validación de datos antes de enviar
    // Por ejemplo, verificar si los campos requeridos están completos

    // Luego, llamar a la función enviar del objeto JAVAMAIL con los datos del formulario
    EnviarMail(formData)
      .then(response => {
        // Manejar la respuesta (por ejemplo, mostrar un mensaje de éxito)
        console.log('El formulario se envió correctamente:', response);
      })
      .catch(error => {
        // Manejar cualquier error que pueda ocurrir durante el envío del formulario
        console.error('Error al enviar el formulario:', error);
      }); 
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  return (

    <div className="contenedor-form-pers">


      <form name="formulario" id="formulario" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-25">
            <label htmlFor="nombre">Nombre personalizado</label>
          </div>
          <div className="col-75">            
            <input 
              type="text" 
              id="nombre" 
              name="nombre" 
              placeholder="nombre personalizado" 
              value={formData.nombre} 
              onChange={handleChange} 
            />
          </div>
        </div>

        <div className="row">
          <div className="col-25">
            <label htmlFor="tapa">Tipo de tapa</label>
          </div>
          <div className="col-75">
            <select name="tapa" id="tapa" value={formData.tapa} onChange={handleChange}>
              <option value="" disabled>Seleccione un tipo de tapa</option>
              <option value="Blanda">Blanda</option>
              <option value="Dura">Dura</option>
            </select>
          </div>
        </div>

        <div className="row">
          <div className="col-25">
            <label htmlFor="email">Mail</label>
          </div>
          <div className="col-75">
            <input 
              type="email" 
              id="email" 
              name="email" 
              placeholder="jose@gmail.com" 
              value={formData.email} 
              onChange={handleChange}
            />
          </div>
        </div>

        

        <div className="row">
          <input type="submit" value="Enviar" />
        </div>
        <p id="infoFormMail"></p>
      </form>
    </div>
  )
}

export default FormLibroPers;