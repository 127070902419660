import '../style/HomePage.css';
import 'alertifyjs/build/css/alertify.css';
import Card from '../componentes/Card';
import Destacado from '../componentes/Destacado';
import tapasAventurasDe from '../imagenes/tapasAventurasDe.jpg';
import cardBuenSaber from '../imagenes/cardBuenSaber.jpg';
import infantilesCard from '../imagenes/infantilesCard.jpg';
import adolescentesCard from '../imagenes/adolescentesCard.jpg';
import adultosCard from '../imagenes/adultosCard.jpg';
import tecnologiaCard from '../imagenes/tecnologiaCard.jpg';

import React, { useEffect } from 'react';

import alertify from 'alertifyjs';


function HomePage() {
  
  // Función para mostrar la alerta
  useEffect(() => {
    alertify.alert('Aviso de Cookies', 'En este sitio web se utilizan cookies para analizar el tráfico y medir los anuncios. <a href="https://www.elbuensaber.com/infocookies">Más información sobre cómo usamos las cookies</a>');
  }, []);
  

  return (
    <main className='main-home-page'>
      
      <div className='contenedor-cards'> 
        <Card 
          url={'/personalizados'}
          imagen={tapasAventurasDe}
          titulo={'Libros Personalizados'}
          descripcion={'Personaliza tu libro'}
        />
        <Card 
          url={'/librosbs'}
          imagen={cardBuenSaber}
          titulo={'Libros El Buen Saber'}
          descripcion={'Nuestros Títulos Originales!!!'}
        />
        <Card 
          url={'/infantiles'}
          imagen={infantilesCard}
          titulo={'Libros y Cuentos Infantiles'}
          descripcion={'Para los más pequeños!!!'}
        />
        <Card 
          url={'/adolescentes'}
          imagen={adolescentesCard}
          titulo={'Libro para Jóvenes'}
          descripcion={'Con toda la emoción y aventura!!!'}
        />
        <Card 
          url={'/adultos'}
          imagen={adultosCard}
          titulo={'Libros para Adultos'}
          descripcion={'Historia, ficción, novelas, autoayuda'}
        />
        <Card 
          url={'/tecnologia'}
          imagen={tecnologiaCard}
          titulo={'Tecnología'}
          descripcion={'e-readers, tablets, portátiles y mas...'}
        />
        
      </div>
    </main>
  );
};

export default HomePage;




