import Libro from '../componentes/Libro';
import LibroData from '../data/libroData.json';
import { h2Personalizado } from '../textos/TxtLibrosPersonalizados';
import '../style/LibrosPersonalizados.css';

function LibrosPersonalizados() {
  return (      
    <main className='main-personalizados'>
      <h1 className="titulo1">Novedad: Libros personalizados</h1>
      <h2 className="titulo2">{h2Personalizado}</h2>      
      {LibroData.map((libro, index) => (
        <Libro
          key={index}
          ruta={libro.ruta}
          imagen={libro.imagen}
          altDescripcion={libro.altDescripcion}
          titulo={libro.titulo}          
        />
      ))} 
      
    </main>    
  );
};

export default LibrosPersonalizados;