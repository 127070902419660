import '../style/Destacado.css';

function Destacado({ imagen, altImagen, tituloGeneral,tituloLibro, texto1, texto2 }) {
  return (
    <div className='contenedor-principal-destacado'>
      
        <div className='contenedor-destacado-foto'>
          <img src={imagen} alt={altImagen} />
        </div>
        <div className='contenedor-destacado-texto'>
          <h1>{tituloGeneral}</h1>
          <h2>{tituloLibro}</h2>
          <p>{texto1}</p>
          <p>{texto2}<a href="mailto:elbuensaberlibros@gmail.com">elbuensaberlibros@gmail.com</a></p>
        </div>
      
    </div>
  );
};

export default Destacado;