import '../style/FichaLibro.css';
import FormLibroPers from '../componentes/FormLibroPers';

function FichaLibro({ path, imagen, altImagen, titulo, titulo2, resumen, contenido, linkbookmundo, showForm }) {
  return (
    <main className='main-ficha-libro'>
      <article className='content-principal-serie'>

        <div className='content-image-libro'>
          <img src={imagen} alt={altImagen} />
          <h3 className='tituloSerie'>{titulo}</h3>
        </div>

        <div className='content-descripcion'>
          <h2>{titulo2}</h2>
          <p>{contenido}</p>
          <h3>Resumen:</h3>
          <p>{resumen}</p>
        </div>
        <div className='content-serie-button'>
          <a href={linkbookmundo} className='button-serie'>Adquirir Libro</a>
        </div>
      </article>
      {showForm && <FormLibroPers sectionIdentifier={path} />}
       
    </main>
  );
};

export default FichaLibro;  