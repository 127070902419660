import { Link } from 'react-router-dom';
import '../style/Producto.css';

function Producto({ ruta, imagen, altDescripcion, titulo, changeClass }) {  

  const defaultClassName = 'producto-img';  // Define tu clase por defecto
  const combinedClassNames = changeClass || defaultClassName;

  return (
    <div className="content-producto">
      <Link to={ruta} alt={altDescripcion}>
        <div className='content-producto-img' >
          <img src={imagen} className={combinedClassNames} />
        </div>
        <div className='content-producto-titulo'>
          <span className='tituloProducto'>{titulo}</span>
        </div>
      </Link>
    </div>
  );
};

export default Producto;
