import { Route, Routes } from 'react-router-dom';
import LibrosPersonalizados from './LibrosPersonalizados';
import FichaLibro from '../componentes/FichaLibro';
import fichaLibroData from '../data/fichaLibroData.json';
import NotFoundPage from './NotFoundPage';

function PersonalizadosRoutes() {
  return (
    <Routes>
      <Route
        path='/'
        element={<LibrosPersonalizados />}
      />
      {fichaLibroData.map((fld, index) => (
        <Route
          key={index}
          path={fld.path}
          element={
            <FichaLibro   
              showForm={true}           
              path={fld.path}
              imagen={fld.imagen}
              altImagen={fld.altImagen}
              titulo={fld.titulo}
              titulo2={fld.titulo2}
              contenido={fld.contenido}
              resumen={fld.resumen}
              linkbookmundo={fld.linkbookmundo}
            />
          }
        />
      ))}
      {/* Agrega más rutas aquí para otras páginas dentro de /personalizados */}
      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  );
}

export default PersonalizadosRoutes;  
