import '../style/Tecnologia.css';
import Producto from '../componentes/Producto';

function Tecnologia() {
  return (
    <main className='main-tecnologia'>
      <div className='contenedor-titulo-libros'>
        <h1 className='titulos-libros'>E-Readers</h1>
      </div>
      <div className='contenedor-tecnologia'>
        <Producto
          ruta={'https://amzn.to/3QYXf2f'}
          imagen={'https://m.media-amazon.com/images/I/51tbxm79xwL._AC_UL480_FMwebp_QL65_.jpg'}
          altDescripcion={'Imagen Kindle (modelo de 2022)'}
          titulo={'Kindle (modelo de 2022): nuestro Kindle más compacto y ligero, resolución de 300 ppp y 6 pulgadas, hasta 16 GB'}
        />
        <Producto
          ruta={'https://amzn.to/3KdzP5m'}
          imagen={'https://m.media-amazon.com/images/I/51dBh5Q1OtL._AC_SX679_.jpg'}
          altDescripcion={'Imagen E-Reader Meebook E-Reader P78 Pro'}
          titulo={'Meebook E-Reader P78 Pro | Pantalla de 7.8" Eink Carta 300PPI. Ver más características...'}
        />
        <Producto
          ruta={'https://amzn.to/3VbfF1h'}
          imagen={'https://m.media-amazon.com/images/I/61ZBEwtHpJL._AC_SX679_.jpg'}
          altDescripcion={'Imagen E-Reader Meebook E-Reader M6'}
          titulo={'Meebook E-Reader M6 | Pantalla 6\' Eink Carta 300PPI. Ver más características...'}
        />
        <Producto
          ruta={'https://amzn.to/4bOflfg'}
          imagen={'https://m.media-amazon.com/images/I/61XUdMXS6YL._AC_SX679_.jpg'}
          altDescripcion={'Imagen E-Reader Bigme inkNote Color'}
          titulo={'Bigme inkNote Color + Lite Ereader 10.3" 4G + 64G Ebook Tablet para Leer y Escribir...'}
        />
        <Producto
          ruta={'https://amzn.to/4dSMtEv'}
          imagen={'https://m.media-amazon.com/images/I/61eGz0e6N+L._AC_UL480_FMwebp_QL65_.jpg'}
          altDescripcion={'Imagen E-Reader Kindle Paperwhite'}
          titulo={'Kindle Paperwhite (16 GB) | Ahora con una pantalla de 6,8" y luz cálida ajustable'}
        />
        <Producto
          ruta={'https://amzn.to/4bKEaZv'}
          imagen={'https://m.media-amazon.com/images/I/61Rl35eaKPL._AC_SX679_.jpg'}
          altDescripcion={'Imagen E-Reader Woxter E-Book Scriba 195 Blue'}
          titulo={'Woxter E-Book Scriba 195 Blue- Lector de Libros electrónicos 6" (1024x758, E-Ink Pearl Pantalla más Blanca, EPUB, PDF)...'}
          changeClass="producto-img-h" 
        />
        <Producto
          ruta={'https://amzn.to/3KcmoTq'}
          imagen={'https://m.media-amazon.com/images/I/81sGcEIzKlL._AC_SX679_.jpg'}
          altDescripcion={'Imagen E-Reader Kobo Libra 2'}
          titulo={'Kobo Libra 2 | eReader | Pantalla 7" táctil Carta EInk antirreflejos ...'}
        />
        <Producto
          ruta={'https://amzn.to/3QT7jtA'}
          imagen={'https://m.media-amazon.com/images/I/81fEqEOgRsL._AC_SX679_.jpg'}
          altDescripcion={'Imagen E-Reader Kobo Clara 2E'}
          titulo={'Kobo Clara 2E | eReader | Pantalla táctil antirreflejos de 6” y Alta definición ...'}
        />
      </div>
    </main>

  );
};

export default Tecnologia;