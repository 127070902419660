import '../style/Card.css';
import { NavLink } from 'react-router-dom';

function Card({ url, imagen, titulo, descripcion }) {
  return (
    <NavLink to={url} className='urlCard'>
      <div className='card' style={{ backgroundImage: `url(${imagen})` }}>

        <div className="textos-card">
          <h3>{titulo}</h3>
          <p>{descripcion}</p>
        </div>

      </div >
    </NavLink>
  )
}

export default Card;
