// PrivateRoute.js
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './AuthContext';

const PrivateRoute = () => {
  const { user, isAdmin } = useAuth();

  // Verifica si el usuario está autenticado y tiene rol de administrador
  if (!user || !isAdmin()) {
    // Redirige al usuario a la página de inicio de sesión si no está autorizado
    return <Navigate to="/login" />;
  }

  // Renderiza el contenido protegido si está autenticado y autorizado
  return <Outlet />;
};

export default PrivateRoute;