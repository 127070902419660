/*LOGICA PARA ENVIAR EMAIL*/

function JavaMail(formData) {
    return new Promise((resolve, reject) => {
        console.log(formData.nombre);
        document.querySelector("#infoFormMail").innerHTML = "";
        //Meto los valores de los campos del formulario en variables.
        var nombrePersonalizado = formData.nombre;
        var tipoTapa = formData.tapa;
        var mailRemitente = formData.email;
        var nombreLibro = formData.sectionIdentifier;
        //verifico que los campos no esten vacios
        if ((nombrePersonalizado !== "") && (tipoTapa !== "") && (mailRemitente !== "")) {

            // Instanciar el Objeto AJAX que existe en todos los Navegadores Web    
            var xhr = new XMLHttpRequest();
            // Metodo POST, JavaMailController
            xhr.open("POST", "../JavaMailController");
            xhr.onreadystatechange = function () {
                if (xhr.readyState == 4 && xhr.status == 200) {
                    //si se envia el mail devuelve una respuesta
                    document.querySelector("#infoFormMail").innerHTML = xhr.responseText;

                } else if (xhr.readyState !== 2 && xhr.readyState !== 3 && xhr.status !== 200) {
                    //Respuesta en caso de Error!
                    document.querySelector("#infoFormMail").innerHTML = xhr.responseText;
                }

            };
            // objeto para enviar los parametros del formulario
            var DATOS_MAIL = {};
            DATOS_MAIL.nomP = nombrePersonalizado;
            DATOS_MAIL.tipoT = tipoTapa;
            DATOS_MAIL.mailR = mailRemitente;
            DATOS_MAIL.nomL = nombreLibro;

            // envio los datos del mensaje en JSON
            var mailStringJSON = JSON.stringify(DATOS_MAIL);
            xhr.send(mailStringJSON);
            console.log(mailStringJSON);
        } else {
            document.querySelector("#infoFormMail").innerHTML = "Debe completar todos los campos!";
            reject("Faltan campos en el formulario"); // Rechazamos la promesa si faltan campos en el formulario
        }
    });
};

export default JavaMail;





