import '../style/Footer.css';

function Footer() {
  return (
    <footer>
      <p>© 2016 - El Buen Saber | e-mail: elbuensaberlibros@gmail.com</p>
    </footer>
  );
};

export default Footer;