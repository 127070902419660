import Nav from './componentes/Nav';
import Header from './componentes/Header';
import Footer from './componentes/Footer';
import './App.css';

import { Routes, Route } from 'react-router-dom';
import { AuthProvider } from './auth/AuthContext';



import HomePage from './pages/HomePage';
import NotFoundPage from './pages/NotFoundPage';
import PersonalizadosRoutes from './pages/PersonalizadosRoutes';
import InfoCookies from './pages/InfoCookies';
import LibrosBS from './pages/LibrosBS';
import Adolescentes from './pages/Adolescentes';
import Adultos from './pages/Adultos';
import Infantiles from './pages/Infantiles';
import Juegos from './pages/Juegos';
import Tecnologia from './pages/Tecnologia';
import PrivateRoute from './auth/PrivateRoute';
import ElbuenAdmin from './pages/Admin/ElbuenAdmin';
import LoginPage from './pages/LoginPage';


function App() {
  return (

    <div className="App">

      <Header />
      <Nav />
      <AuthProvider>
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/personalizados/*' element={<PersonalizadosRoutes />} />
          <Route path='/librosbs' element={<LibrosBS />} />
          <Route path='/adolescentes' element={<Adolescentes />} />
          <Route path='/infantiles' element={<Infantiles />} />
          <Route path='/adultos' element={<Adultos />} />
          <Route path='/juegos' element={<Juegos />} />
          <Route path='/tecnologia' element={<Tecnologia />} />
          <Route path='/infocookies' element={<InfoCookies />} />
          <Route path='/login' element={<LoginPage />} />
          <Route path='*' element={<NotFoundPage />} />
          {/* Rutas protegidas */}
          <Route element={<PrivateRoute />}>
            <Route path="/elbuenadmin/*" element={<ElbuenAdmin />} />
          </Route>
        </Routes>
      </AuthProvider>
      <Footer />

    </div>

  );
}

export default App;
