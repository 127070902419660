import '../style/NotFoundPage.css';
import libro404 from '../imagenes/libro404.png';

function NotFoundPage() {
  return (
    <main className='main-not-found-page'>
      <div className='content-404'>
        <span>No se ha podido cargar la página o no existe.</span>
        <img src={libro404} alt='Imágen libro 404' />
      </div>
    </main>
  );
};

export default NotFoundPage;