import { Link } from 'react-router-dom';
import '../style/Libro.css';

function Libro({ ruta, imagen, altDescripcion, titulo }) {
  return (
    <div className="content-libro">
      <Link to={ruta} alt={altDescripcion}>
        <div className='content-libro-img' >
          <img src={imagen} />
        </div>
        <div className='content-libro-titulo'>
          <span className='tituloSerie'>{titulo}</span>
        </div>
      </Link>
    </div>
  );
};

export default Libro;




