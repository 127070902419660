import '../style/Header.css';
import { FaFacebookSquare } from "react-icons/fa";
import { FaSquareInstagram } from "react-icons/fa6";
import { FaSquareXTwitter } from "react-icons/fa6";
import { RiTiktokLine } from "react-icons/ri";

import logoBS from '../imagenes/logobs2.png';


function Header() {
	return (
		<header>
			<div id='titulosHead'>
				<img id='logoini' src={logoBS} alt='Logo' /> 
				<h1>El Buen Saber</h1>
				<h2>Los mejores e-books y libros a tu disposición</h2>
			</div>
			<div id='socialHeader'>
				<a href='https://www.facebook.com/El.Buen.Saber/' target='_blank'><FaFacebookSquare className='socialIcono' /></a>
				<a href='https://www.instagram.com/elbuensaberlibros/' target="_blank"><FaSquareInstagram className='socialIcono' /></a>
				<a href='https://twitter.com/elbuenslibros' target='_blank'><FaSquareXTwitter className='socialIcono' /></a>
				<a href='https://www.tiktok.com/@el.buen.saber.libros'><RiTiktokLine className='socialIcono' /></a>
			</div>
		</header>

	);
}

export default Header;